import {
  applySnapshot,
  Instance,
  SnapshotIn,
  SnapshotOut,
  types,
  flow,
} from "mobx-state-tree";

import AuthStore from "src/stores/AuthStore";
import WalletStore from "src/stores/WalletStore";
import Dialog from "src/stores/Dialog";
import OrganizationStore from "src/stores/OrganizationStore";
import RequestStore from "src/stores/RequestStore";
import Coins from "src/stores/Coins";
import CustodyRequestStore from "src/stores/CustodyRequestStore";
import CustodyRequestStoreV2 from "src/stores/CustodyRequestStoreV2";
import { isKodaAdmin } from "src/libs/env";
import CoinBalances from "src/stores/CoinBalances";
import CoinBalancesV2 from "./CoinBalancesV2";
import Blockchains from "./Blockchains";
import VaspStore from "./Vasp";
import CountriesStore from "./Countries";
import MigrationStatus from "./MigrationStatus";
import CoinRegistrationsCountStore from "./CoinRegistrationsCountStore";

let store: IStore | null = null;

const Store = types
  .model({
    authStore: types.optional(
      types.late(() => AuthStore),
      {},
    ),
    walletStore: types.optional(
      types.late(() => WalletStore),
      {},
    ),
    organizationStore: types.optional(
      types.late(() => OrganizationStore),
      {},
    ),
    requestStore: types.optional(
      types.late(() => RequestStore),
      {},
    ),
    custodyRequestStore: types.optional(
      types.late(() => CustodyRequestStore),
      {},
    ),
    custodyRequestStoreV2: types.optional(
      types.late(() => CustodyRequestStoreV2),
      {},
    ),
    coins: types.optional(
      types.late(() => Coins),
      {},
    ),
    coinBalances: types.optional(
      types.late(() => CoinBalances),
      {},
    ),
    coinBalancesV2: types.optional(
      types.late(() => CoinBalancesV2),
      {},
    ),
    dialog: types.optional(
      types.late(() => Dialog),
      {},
    ),
    blockchains: types.optional(
      types.late(() => Blockchains),
      {},
    ),
    vasps: types.optional(
      types.late(() => VaspStore),
      {},
    ),
    countriesStore: types.optional(
      types.late(() => CountriesStore),
      {},
    ),
    migrationStatus: types.optional(
      types.late(() => MigrationStatus),
      {},
    ),
    coinRegistrationsCountStore: types.optional(
      types.late(() => CoinRegistrationsCountStore),
      {},
    ),
  })
  .actions((self) => {
    const initialize = flow(function* () {
      const { checkAccessToken, checkExpiredPassword } = self.authStore;
      checkAccessToken();
      yield self.authStore.initialize();
      checkExpiredPassword();

      const orgId = self.authStore.user.orgId;

      yield Promise.all([
        self.blockchains.fetch(),
        self.coins.initialize(),
        self.walletStore.fetchMasterWallets(),
        self.organizationStore.initialize(orgId),
        ...(isKodaAdmin
          ? [
              self.custodyRequestStore.fetchPendingWithdrawalCount(),
              self.custodyRequestStore.fetchPendingRequestUpgradeCount(),
              self.custodyRequestStore.fetchPendingRegisterWalletCount(),
              self.custodyRequestStoreV2.fetchPendingWithdrawalCount(),
              self.custodyRequestStoreV2.fetchPendingRequestUpgradeCount(),
              self.custodyRequestStoreV2.fetchPendingRegisterWalletCount(),
              self.coinRegistrationsCountStore.fetch(),
            ]
          : [
              self.requestStore.fetchPendingApprovalInboxCount(),
              ...(self.authStore.user?.isSuperAdmin
                ? [self.migrationStatus.fetch()]
                : []),
            ]),
      ]);
    });

    return { initialize };
  });

export type IStore = Instance<typeof Store>;
export type IStoreSnapshotIn = SnapshotIn<typeof Store>;
export type IStoreSnapshotOut = SnapshotOut<typeof Store>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRootStore = (isServer = false, snapshot: any = null) => {
  if (isServer) {
    store = Store.create({});
  }
  if (store === null) {
    store = Store.create({});
  }
  if (snapshot) {
    applySnapshot(store, snapshot);
  }
  return store;
};
