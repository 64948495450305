import { AccountResponse } from "src/apis/accounts";
import { requestAPI } from "src/apis/requestApi";
import {
  QrCodeDto,
  CustodyRequestV2ControllerApiFactory,
  CustodyRequestV2Status,
  PaginationWithdrawalCustodyRequestV2Dto,
  DetailedWithdrawalCustodyRequestV2Dto,
  PaginationWalletCustodyRequestV2Dto,
  DetailedWalletCustodyRequestV2Dto,
  SimpleBalanceDto,
} from "src/__generate__/api";
import { Pageable } from "src/apis/interface";
import { AxiosPromise } from "axios";
import {
  ALL_CUSTODY_REQUEST_V2_REGISTER_WALLET_STATUSES,
  ALL_CUSTODY_REQUEST_V2_TRANSFER_STATUSES,
} from "src/interfaces/v2/request";

const custodyRequestV2ControllerApiFactory = () =>
  CustodyRequestV2ControllerApiFactory(undefined, "", requestAPI());

export type CustodyTransferType = "wallet" | "withdrawal";

export type CustodySignType = "COMMIT" | "CONFIRM";

export type Signing = {
  account: AccountResponse;
  logs: SigningLog[];
};

export type SigningLog = {
  type: "QR_CREATION" | "CONFIRMATION";
  createdAt: number;
};

export type Approval = {
  account: AccountResponse;
  type: "ALLOW";
  memo: string | null;
  authority: string;
  createdAt: number;
};

export const requestTransfers = async (request: {
  statuses: CustodyRequestV2Status[];
  search?: string;
  pageable: Pageable;
}): Promise<PaginationWithdrawalCustodyRequestV2Dto> => {
  const { statuses, search, pageable } = request;
  const { page, size } = pageable;
  const response =
    await custodyRequestV2ControllerApiFactory().getWithdrawalCustodyRequests(
      undefined,
      statuses,
      search,
      page,
      size,
    );
  return response.data;
};

export const requestTransferById = async (request: {
  requestId: string;
}): Promise<DetailedWithdrawalCustodyRequestV2Dto> => {
  const { requestId } = request;

  const response =
    await custodyRequestV2ControllerApiFactory().getWithdrawalCustodyRequest(
      requestId,
    );
  return response.data;
};

const getRequestTransferStatusCountByStatuses = async (
  statuses: CustodyRequestV2Status[],
) => {
  const response = await requestTransfers({
    statuses,
    pageable: {
      page: 0,
      size: 1,
    },
  });
  return response.pagination.totalCount;
};

export const getRequestTransferProcessCounts = async () => {
  const [
    allCount,
    requestCount,
    signingCount,
    pendingApprovalCount,
    pendingFinalApprovalCount,
  ] = await Promise.all([
    getRequestTransferStatusCountByStatuses(
      ALL_CUSTODY_REQUEST_V2_TRANSFER_STATUSES,
    ),
    getRequestTransferStatusCountByStatuses([CustodyRequestV2Status.Requested]),
    getRequestTransferStatusCountByStatuses([
      CustodyRequestV2Status.PendingSigning,
    ]),
    getRequestTransferStatusCountByStatuses([
      CustodyRequestV2Status.PendingApproval,
    ]),
    getRequestTransferStatusCountByStatuses([
      CustodyRequestV2Status.PendingFinalApproval,
    ]),
  ]);

  return {
    allCount,
    requestCount,
    signingCount,
    pendingApprovalCount,
    pendingFinalApprovalCount,
  };
};

export const requestWallets = async (request: {
  statuses: CustodyRequestV2Status[];
  search?: string;
  pageable: Pageable;
}): Promise<PaginationWalletCustodyRequestV2Dto> => {
  const { statuses, search, pageable } = request;
  const { page, size } = pageable;

  const response =
    await custodyRequestV2ControllerApiFactory().getWalletCustodyRequests(
      undefined,
      statuses,
      search,
      page,
      size,
    );
  return response.data;
};

export const requestWalletById = async (request: {
  requestId: string;
}): Promise<DetailedWalletCustodyRequestV2Dto> => {
  const { requestId } = request;

  const response =
    await custodyRequestV2ControllerApiFactory().getWalletCustodyRequest(
      requestId,
    );
  return response.data;
};

const getRequestRegisterWalletStatusCountByStatuses = async (
  statuses: CustodyRequestV2Status[],
) => {
  const response = await requestWallets({
    statuses,
    pageable: {
      page: 0,
      size: 1,
    },
  });
  return response.pagination.totalCount;
};

export const getRequestRegisterWalletProcessCounts = async () => {
  const [
    allCount,
    requestCount,
    signingCount,
    pendingApprovalCount,
    pendingFinalApprovalCount,
  ] = await Promise.all([
    getRequestRegisterWalletStatusCountByStatuses(
      ALL_CUSTODY_REQUEST_V2_REGISTER_WALLET_STATUSES,
    ),
    getRequestRegisterWalletStatusCountByStatuses([
      CustodyRequestV2Status.Requested,
    ]),
    getRequestRegisterWalletStatusCountByStatuses([
      CustodyRequestV2Status.PendingSigning,
    ]),
    getRequestRegisterWalletStatusCountByStatuses([
      CustodyRequestV2Status.PendingApproval,
    ]),
    getRequestRegisterWalletStatusCountByStatuses([
      CustodyRequestV2Status.PendingFinalApproval,
    ]),
  ]);

  return {
    allCount,
    requestCount,
    signingCount,
    pendingApprovalCount,
    pendingFinalApprovalCount,
  };
};

const distinguishSignType = <T>(
  signType: CustodySignType,
  commitCallback: T,
  confirmCallback: T,
): T => {
  if (signType === "COMMIT") {
    return commitCallback;
  }
  return confirmCallback;
};

export const createQRCodeForSigning = async (request: {
  type: CustodyTransferType;
  signType: CustodySignType;
  requestId: string;
  passphrase: string;
  otpCode: string;
}): Promise<QrCodeDto> => {
  const { type, signType, requestId, passphrase, otpCode } = request;
  const byType: Record<CustodyTransferType, () => AxiosPromise<QrCodeDto>> = {
    wallet: () =>
      distinguishSignType(
        signType,
        custodyRequestV2ControllerApiFactory().createWalletCommitSigning,
        custodyRequestV2ControllerApiFactory().createWalletConfirmSigning,
      )(requestId, {
        passphrase,
        otpCode,
      }),
    withdrawal: () =>
      distinguishSignType(
        signType,
        custodyRequestV2ControllerApiFactory().createWithdrawalCommitSigning,
        custodyRequestV2ControllerApiFactory().createWithdrawalConfirmSigning,
      )(requestId, {
        passphrase,
        otpCode,
      }),
  };
  const response = await byType[type]();
  return response.data;
};

export const recreateQRCodeForSigning = async (request: {
  type: CustodyTransferType;
  signType: CustodySignType;
  requestId: string;
  passphrase: string;
  otpCode: string;
}): Promise<QrCodeDto> => {
  const { type, signType, requestId, passphrase, otpCode } = request;

  const byType: Record<CustodyTransferType, () => AxiosPromise<QrCodeDto>> = {
    wallet: () =>
      distinguishSignType(
        signType,
        custodyRequestV2ControllerApiFactory().recreateWalletCommitSigning,
        custodyRequestV2ControllerApiFactory().recreateWalletConfirmSigning,
      )(requestId, {
        passphrase,
        otpCode,
      }),
    withdrawal: () =>
      distinguishSignType(
        signType,
        custodyRequestV2ControllerApiFactory().recreateWithdrawalCommitSigning,
        custodyRequestV2ControllerApiFactory().recreateWithdrawalConfirmSigning,
      )(requestId, {
        passphrase,
        otpCode,
      }),
  };
  const response = await byType[type]();
  return response.data;
};

export const validateRequestSigning = async (request: {
  type: CustodyTransferType;
  requestId: string;
}): Promise<void> => {
  const { type, requestId } = request;

  const byType: Record<CustodyTransferType, () => AxiosPromise<void>> = {
    wallet: () =>
      custodyRequestV2ControllerApiFactory().validateWalletRequestSigning(
        requestId,
      ),
    withdrawal: () =>
      custodyRequestV2ControllerApiFactory().validateWithdrawalRequestSigning(
        requestId,
      ),
  };
  await byType[type]();
};

export const confirmQRCode = async (request: {
  type: CustodyTransferType;
  requestId: string;
  qrCodeData: string;
  signType: CustodySignType;
}): Promise<void> => {
  const { type, requestId, qrCodeData, signType } = request;

  const byType: Record<CustodyTransferType, () => AxiosPromise<void>> = {
    wallet: () =>
      distinguishSignType(
        signType,
        custodyRequestV2ControllerApiFactory().confirmWalletCommitSigning,
        custodyRequestV2ControllerApiFactory().confirmWalletConfirmSigning,
      )(requestId, {
        qrCodeData,
      }),
    withdrawal: () =>
      distinguishSignType(
        signType,
        custodyRequestV2ControllerApiFactory().confirmWithdrawalCommitSigning,
        custodyRequestV2ControllerApiFactory().confirmWithdrawalConfirmSigning,
      )(requestId, {
        qrCodeData,
      }),
  };
  await byType[type]();
};

export const approveTransfer = async (request: {
  requestId: string;
  passphrase: string;
  otpCode: string;
}): Promise<DetailedWithdrawalCustodyRequestV2Dto> => {
  const { requestId, passphrase, otpCode } = request;

  const response =
    await custodyRequestV2ControllerApiFactory().approveWithdrawal(requestId, {
      passphrase,
      otpCode,
    });
  return response.data;
};

export const approveWallet = async (request: {
  requestId: string;
  passphrase: string;
  otpCode: string;
}): Promise<DetailedWalletCustodyRequestV2Dto> => {
  const { requestId, passphrase, otpCode } = request;

  const response = await custodyRequestV2ControllerApiFactory().approveWallet(
    requestId,
    {
      passphrase,
      otpCode,
    },
  );
  return response.data;
};

export const finalApproveTransfer = async (request: {
  requestId: string;
  passphrase: string;
  otpCode: string;
}): Promise<DetailedWithdrawalCustodyRequestV2Dto> => {
  const { requestId, passphrase, otpCode } = request;

  const response =
    await custodyRequestV2ControllerApiFactory().finalApproveWithdrawal(
      requestId,
      {
        passphrase,
        otpCode,
      },
    );
  return response.data;
};

export const finalApproveWallet = async (request: {
  requestId: string;
  passphrase: string;
  otpCode: string;
}): Promise<DetailedWalletCustodyRequestV2Dto> => {
  const { requestId, passphrase, otpCode } = request;

  const response =
    await custodyRequestV2ControllerApiFactory().finalApproveWallet(requestId, {
      passphrase,
      otpCode,
    });
  return response.data;
};

export const getCustodyBalances = async (request: {
  date: string;
}): Promise<SimpleBalanceDto[]> => {
  const { date } = request;

  const response =
    await custodyRequestV2ControllerApiFactory().getCustodyBalances(date);
  return response.data;
};

export const getCustodyBalancesCsv = async (request: { date: string }) => {
  const { date } = request;

  const response =
    await custodyRequestV2ControllerApiFactory().getCustodyBalancesCsv(date);
  return response.data;
};
