import _ from "lodash";
import { Instance, flow, types } from "mobx-state-tree";
import { Blockchain, SupportedBlockchainDto } from "src/__generate__";
import { getSupportedBlockchains } from "src/apis/blockchains";

const Blockchains = types
  .model("Blockchains", {
    data: types.optional(
      types.array(types.frozen<SupportedBlockchainDto>()),
      [],
    ),
  })
  .views((self) => ({
    get blockchains() {
      return _.orderBy(
        Array.from(self.data.values()),
        ["version", "blockchain", "symbol"],
        ["desc", "asc", "asc"],
      ).map((x) => x?.blockchain as Blockchain);
    },
  }))
  .actions((self) => {
    const fetch = flow(function* () {
      const response: RetrieveAsyncFunc<typeof getSupportedBlockchains> =
        yield getSupportedBlockchains();

      self.data.replace(response);
    });

    const checkV2Chain = (blockchain: Blockchain) => {
      return Boolean(
        self.data.find(
          (item) => item.blockchain === blockchain && item.version === 2,
        ),
      );
    };

    const checkTestnet = (blockchain: Blockchain) => {
      return Boolean(
        self.data.find(
          (item) => item.blockchain === blockchain && item.isTestnet,
        ),
      );
    };

    const checkDeprecated = (blockchain: Blockchain) => {
      return Boolean(
        self.data.find(
          (item) => item.blockchain === blockchain && item.isDeprecated,
        ),
      );
    };

    const getBlockchain = (blockchain: Blockchain) => {
      return self.data.find((item) => item.blockchain === blockchain);
    };

    return {
      fetch,
      checkV2Chain,
      checkTestnet,
      checkDeprecated,
      getBlockchain,
    };
  });

export type IBlockchains = Instance<typeof Blockchains>;
export default Blockchains;
