import React from "react";
import _ from "lodash";
import Icon, { IconProps } from "@mui/material/Icon";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import images from "src/images";
import { SvgIcon } from "@mui/material";
import { shouldForwardProp } from "src/themes/config";

// https://material.io/resources/icons/?style=baseline
export const icons = [
  "repeat",
  "close",
  "warning",
  "donut_large",
  "home",
  "account_circle",
  "border_outer",
  "repeat",
  "description",
  "keyboard_arrow_left",
  "keyboard_arrow_right",
  "more_hoiz",
  "open_in_new",
  "refresh",
  "event_note",
  "error_outline",
  "filter_list",
  "check",
  "clear",
  "trending_flat",
  "help_outline",
  "info_outline",
  "remove",
  "arrow_forward",
  "check_box",
  "check_box_outlint_blank",
  "account_balance_wallet",
  "error",
  "arrow_drop_down",
  "arrow_drop_up",
  "content_copy",
  "flare",
  "feedback",
  "add",
  "assessment",
  "functions",
  "file_download",
  "file_upload",
  "check_circle",
  "gradient",
  "hourglass_full",
  "vpn_key",
  "search",
  "help",
  "computer",
  "history",
  "eth",
  "eth_box",
  "eth_deprecated",
  "eth_deprecated_box",
  "klaytn",
  "klaytn_box",
  "klaytn_deprecated",
  "klaytn_deprecated_box",
  "polygon",
  "polygon_box",
  "polygon_deprecated",
  "polygon_deprecated_box",
  "filterListSelected",
  "gasStation",
  "paginationFirst",
  "paginationLast",
  "user",
  "lens",
  "people",
  "android_custom",
  "ios",
  "edit",
  "delete",
  "visibility",
  "keyboard_arrow_down",
  "keyboard_arrow_up",
  "settings",
  "list",
  "btc",
  "btc_box",
  "btc_deprecated",
  "btc_deprecated_box",
  "cancel",
  "more_horiz",
  "class",
  "email",
  "verified_user",
  "subMenu",
  "arrow_right",
  "av_timer",
  "security",
  "calendar_today",
  "call_made",
  "public",
  "exit_to_app",
  "how_to_reg",
  "group",
  "local_post_office",
  "check_circle_outline",
  "check_circle",
  "forward_to_inbox",
  "call_missed_outgoing",
  "person_remove",
  "person",
  "build",
  "chrome_reader_mode",
  "business",
  "copyright",
  "litecoin_box",
  "bsc",
  "bsc_box",
  "over",
  "over_box",
  "wemix",
  "wemix_box",
  "add_circle",
  "remove_circle",
  "update",
  "coin_add",
] as const;

export type IconType = `typeof icons`[number];

export type Props = Omit<IconProps, "color" | "fontSize"> & {
  name: IconType;
  color?: string;
  fontSize?: IconProps["fontSize"] | number;
};

const SvgIconView = styled("span", {
  shouldForwardProp,
})<{ $color?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ $color }) =>
    $color
      ? css`
          & path {
            fill: ${$color};
          }
        `
      : css`
          & svg {
            fill: none;
          }
        `}
`;

const isCustomNames = (name: string) => _.keys(images.icons).includes(name);

const MaterialIcon = (props: Props) => {
  const { style, className, name, color, fontSize, ...rest } = props;
  const isFontSizeString = typeof fontSize === "string";
  const attrs = isFontSizeString
    ? {
        style: {
          ...style,
          color,
        },
        fontSize: fontSize as IconProps["fontSize"],
      }
    : {
        style: {
          ...style,
          color,
          fontSize,
        },
      };
  if (isCustomNames(name)) {
    return (
      <SvgIconView $color={color}>
        <SvgIcon
          {...attrs}
          {...rest}
          className={className}
          component={_.get(images, ["icons", name])}
        />
      </SvgIconView>
    );
  }
  return (
    <Icon className={className} {...attrs} {...rest}>
      {name}
    </Icon>
  );
};

export default MaterialIcon;
