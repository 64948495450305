import { flow, Instance, types } from "mobx-state-tree";

import {
  Balance,
  changeWalletName,
  walletInitialized,
  validateAddress,
} from "src/apis/wallet";
import {
  changeWalletName as changeWalletNameV2,
  walletInitialized as walletInitializedV2,
  validateAddress as validateAddressV2,
} from "src/apis/v2/wallet";
import AddressBooks from "src/stores/AddressBooks";
import Balances from "src/stores/Balances";
import WalletMembers from "src/stores/WalletMembers";
import {
  WalletApprovalPolicyDto,
  WalletStatus,
  Blockchain,
  WalletWithRequestDto,
  WalletV2WithRequestDto,
} from "src/__generate__/api";
import { getRootStore } from "../StoreHelper";

const MasterWallet = types
  .model("MasterWallet", {
    id: types.identifier,
    createdAt: types.string,
    name: types.optional(types.string, ""),
    blockchain: types.frozen<Blockchain>(),
    status: types.frozen<WalletStatus>(),
    approvalPolicy: types.frozen<WalletApprovalPolicyDto>(),
    initialized: types.optional(types.boolean, false),
    masterWallet: types.frozen<WalletWithRequestDto | WalletV2WithRequestDto>(),
    members: types.optional(
      types.late(() => WalletMembers),
      {},
    ),
    balances: types.optional(
      types.late(() => Balances),
      {},
    ),
    addresseBooks: types.optional(
      types.late(() => AddressBooks),
      {},
    ),
  })
  .views((self) => {
    return {
      get isUserWallet() {
        return false;
      },
      get myWallet() {
        return self.masterWallet;
      },
      get walletError() {
        return null;
      },
      get walletStatus(): WalletStatus {
        return self.status;
      },
      get myAddress() {
        return self.masterWallet.address ?? "";
      },
      get isFirstWallet() {
        const user = getRootStore(self).authStore.user;
        return (
          this.walletStatus === WalletStatus.Active &&
          user?.isWalletOwnerAndManager(self.id) &&
          !self.initialized
        );
      },
      get isDeprecated() {
        return (
          (!getRootStore(self).blockchains?.checkV2Chain(self.blockchain) &&
            self.blockchain !== Blockchain.Bitcoin) ??
          false
        );
      },
    };
  })
  .actions((self) => {
    const update = (params: {
      name: string;
      status: WalletStatus;
      approvalPolicy: WalletApprovalPolicyDto;
      initialized: boolean;
      masterWallet: WalletWithRequestDto | WalletV2WithRequestDto;
      balances: Balance[];
    }) => {
      const {
        name,
        status,
        approvalPolicy,
        initialized,
        masterWallet,
        balances,
      } = params;
      self.name = name;
      self.status = status;
      self.approvalPolicy = approvalPolicy;
      self.initialized = initialized;
      self.masterWallet = masterWallet;
      self.balances.updateBalances(balances);
    };

    const changeApprovalPolicy = (policy: WalletApprovalPolicyDto) => {
      self.approvalPolicy = policy;
    };

    const changeName = flow(function* (params: {
      name: string;
      passphrase: string;
      otpCode: string;
    }) {
      const { id } = self;
      const { name, passphrase, otpCode } = params;
      let changeName;
      if (getRootStore(self).blockchains?.checkV2Chain(self.blockchain)) {
        changeName = changeWalletNameV2;
      } else {
        changeName = changeWalletName;
      }
      yield changeName({
        walletId: id,
        name,
        passphrase,
        otpCode,
      });
      self.name = name;
    });

    const verifyAddress = flow(function* (address: string) {
      try {
        let validate;
        if (getRootStore(self).blockchains?.checkV2Chain(self.blockchain)) {
          validate = validateAddressV2;
        } else {
          validate = validateAddress;
        }
        yield validate({
          address,
          blockchain: self.blockchain,
        });
        return true;
      } catch (error) {
        return false;
      }
    });

    const showFirstWalletDialog = () => {
      self.initialized = true;
      if (getRootStore(self).blockchains?.checkV2Chain(self.blockchain)) {
        walletInitializedV2(self.id);
      } else {
        walletInitialized(self.id);
      }
    };

    return {
      update,
      changeName,
      changeApprovalPolicy,
      verifyAddress,
      showFirstWalletDialog,
    };
  });

export type IMasterWallet = Instance<typeof MasterWallet>;
export default MasterWallet;
